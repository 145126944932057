import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconPlus = ({ color = colorGray, ...props }) => (
  <svg width="12" height="12" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9425 13.25V21.6925H11.4425V13.25H3V11.75H11.4425V3.3075H12.9425V11.75H21.385V13.25H12.9425Z"
      fill={color}
    />
  </svg>
);

IconPlus.propTypes = {
  color: PropTypes.string,
};

export default IconPlus;

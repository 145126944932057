import useProductListingData from 'hooks/useProductListingData';
import { GET_PRODUCTS_STATIC_DATA } from 'mocks/queries';
import getVisitorLocationQueryVariables from 'commons/getVisitorLocationQueryVariables';
import useUserLocation from 'global-state/userLocation/useUserLocation';

// This hook should be used when we need to render an instance of
// ProductListingPage for a set of known product IDs. Since it includes the
// same functionality as category pages (e.g. sorting, filtering, restoring
// scroll position), it should not be used when rendering a set of product
// cards in isolation (e.g. product card carousels) - for those use cases,
// useStaticProductCardData should be used instead.
const useStaticProductListingPageData = (
  { productIds = [], showSearchProductsOnly = false },
  options
) => {
  const userLocation = useUserLocation();
  const zipCode = userLocation?.zip ?? '';

  const pageData = useProductListingData(
    GET_PRODUCTS_STATIC_DATA,
    {
      variables: {
        productIds,
        showSearchProductsOnly,
        zipCode,
        ...getVisitorLocationQueryVariables(userLocation?.stateShortName),
      },
      limit: -1,
      skip: productIds.length === 0,
    },
    options
  );

  return productIds.length > 0
    ? pageData
    : { loading: true, products: [], productsToPreload: [] };
};

export default useStaticProductListingPageData;

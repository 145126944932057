import React from 'react';
import PropTypes from 'prop-types';

const IconUserAccount = props => {
  const color = props.color || '#585858';
  return (
    <svg width={20} height={17} viewBox="0 0 20 17" {...props}>
      <g stroke={color} strokeWidth={1.667} fill="none" fillRule="evenodd">
        <path d="M1 17a9 9 0 0118 0M14 4.5a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z" />
      </g>
    </svg>
  );
};

IconUserAccount.propTypes = {
  color: PropTypes.string,
};

export default IconUserAccount;

import axios from 'axios';
import isServer from './isServer';

let clientIPExperimental = null;

setTimeout(async () => {
  if (isServer()) {
    return;
  }
  try {
    const res = await axios.get('https://1.1.1.1/cdn-cgi/trace', {
      timeout: 5000,
    });
    if (+res.status !== 200) {
      return;
    }
    const cipe = res.data
      .split('\n')
      .filter(line => line.indexOf('ip=') === 0)[0]
      .split('=')[1];
    clientIPExperimental = cipe;
  } catch (err) {
    /* ignore */
  }
}, 5000);

const getClientIPExperimental = () => clientIPExperimental;

export default getClientIPExperimental;

import React from 'react';
import PropTypes from 'prop-types';

const IconJoybirdSymbol = props => {
  const color = props.color || '#585858';
  return (
    <svg width={47} height={34} viewBox="0 0 47 34" {...props}>
      <g fill={color} fillRule="nonzero">
        <path d="M31.228 13.652c-4.29 7.062-12.816 6.225-24.376-.784-2.929-1.726-5.283.366-6.015 2.877C-.785 8.788 3.19 6.8 7.375 9.258 17 14.803 24.323 20.714 31.228 13.652z" />
        <path d="M25.683 25.474c11.35-1.465 14.855-11.142 17.785-22.126l3.295-.68-2.93-.68C42.16.262 40.434-.052 38.918.523c-1.622.628-2.72 2.092-3.505 3.557-2.877 5.492-2.458 14.28-11.089 15.43-5.963.838-11.874-1.935-18.098-5.753-2.407-1.465-6.487 1.988-2.825 4.97 4.865 3.975 11.77 7.322 20.138 6.956.21.628.785 2.877.105 5.074-2.93 1.36-10.41 1.36-11.508 2.772h24.899c-1.15-1.412-8.579-1.36-11.508-2.772-.784-2.615.157-5.283.157-5.283zM41.062 1.308c.366 0 .627.261.627.627a.604.604 0 01-.627.628.604.604 0 01-.628-.628c0-.366.261-.627.628-.627z" />
      </g>
    </svg>
  );
};

IconJoybirdSymbol.propTypes = {
  color: PropTypes.string,
};

export default IconJoybirdSymbol;

import React from 'react';

const IconVerified = props => (
  <svg width={14} height={13} viewBox="0 0 14 13" {...props}>
    <g stroke="#585858" strokeWidth={1.5} fill="none" fillRule="evenodd">
      <path strokeLinejoin="round" d="M4.571 4.687L7.518 7.74l5.053-5.6" />
      <path d="M11.2 8.24V10.2c0 .817-.662 1.479-1.48 1.479H3.08c-.818 0-1.48-.662-1.48-1.479V3.08c0-.818.662-1.48 1.48-1.48h4.432" />
    </g>
  </svg>
);

export default IconVerified;

import imageUrls from './image-urls.json';

export const CLEARANCE_WARNING = `This clearance item cannot be canceled, returned, exchanged, or refunded.`;
export const INSPIRATION_PAGE_SEO_DESCRIPTION = `See customer photos featuring our custom-made furniture, or upload your own. See how customers are installing Joybird furniture and enhancing their homes and lives. Search for inspiration, or get fresh ideas on how our custom furniture pieces are enhancing living spaces across the country.`;
export const MATERIAL_SWATCH_PAGE_SEO_DESCRIPTION = `Order Joybird's material swatches to see and feel our fabrics. Our swatches let you match colors and get a sense of texture with our different fabric weaves to find the perfect upholstery for your furniture. We want you to be absolutely certain you'll love your new custom furniture.`;
export const QUICKSHIP_PAGE_SEO_DESCRIPTION = `Our quick ship items that are ready to be shipped in days.`;
export const CLEARANCE_SEO_DESCRIPTION = `Save extra on marked-down items that are ready to be shipped in a few days instead of weeks.`;
export const REVIEWS_PAGE_SEO_DESCRIPTION = `Read reviews from Joybird customers before you buy from us. Search for your sofa, chair or table and find reviews from real customers who ordered that same piece of furniture. Get the inside scoop on the item you want, and see what others customers are saying about our furniture buying process.`;
export const COLLECTIONS_PAGE_SEO_DESCRIPTION = `High quality furniture collections for home or office, Mid-Century inspired, chairs, sofas, ottomans, tables, beds, free shipping.`;
export const MODULAR_SOFAS_PAGE_SEO_DESCRIPTION = `Discover our collection of modern modular sofas and sectionals. Modular sofas provide incredible versatility and customization to meet your seating needs.`;
export const HTTP_CONTEXT_FIELDS = {
  IS_BOT_REQ: 'IS_BOT_REQ',
  IS_DEBUG_REQ: 'IS_DEBUG_REQ',
  IS_SKIP_FOLD_REQ: 'IS_SKIP_FOLD_REQ',
  REQ_DEVICE_TYPE: 'REQ_DEVICE_TYPE',
  USER_AGENT: 'USER_AGENT',
};

export const TIME_OUT = 20000;
export const product_item_availability = {
  enabled: '0',
  out_of_stock: '1',
  disabled: '2',
};
export const ALL_FURNITURE_CATEGORY_IDS = [203, 218, 222];

export const multiCategoryIds = { 565: true, 569: true, 424: true, 213: true };

export const productCardImageSize = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  modular: 'modular',
  thumb: 'thumb',
};

export const PDP_MAIN_VARIANT_IMAGE_IMGIX_PROPS = {
  sizes: '(min-width: 1024px) 70vw, 100vw',
  trim: 'color',
  trimPad: 50,
  trimTol: 10,
  bg: 'fafafa',
  fm: 'jpg',
};

export const FABRIC_PDP_MAIN_IMAGE_IMGIX_PROPS = {
  width: 730,
};
export const FILTER_NAME_TO_LABEL_MAPPING = {
  midcentury: 'Mid-Century',
  seatingcapacity: 'Seating Capacity',
  shipsby: 'Ships By',
  'tv stands & media consoles': 'TV Stands & Media Consoles',
  'up to 1 week': 'Up to 1 Week',
  'up to 2 weeks': 'Up to 2 Weeks',
  'up to 4 weeks': 'Up to 4 Weeks',
  'up to 8 weeks': 'Up to 8 Weeks',
  'up to 6 weeks': 'Up to 6 Weeks',
  'up to 10 weeks': 'Up to 10 Weeks',
  '72” and under': '72” And Under',
  '72” to 86”': '72” To 86”',
  producttype: 'Product Type',
};
export const PRODUCT_TYPES = [
  'bar',
  'bed',
  'bench',
  'bookcase',
  'bookshelf',
  'bowl',
  'chair',
  'chaise',
  'chandelier',
  'chest',
  'clocks',
  'coffee table',
  'console',
  'credenza',
  'cushion',
  'daybed',
  'desk',
  'desk chair',
  'dining table',
  'dresser',
  'end table',
  'entry table',
  'glider',
  'headboard',
  'lamp',
  'lighting',
  'loveseat',
  'magazine rack',
  'mirror',
  'modular',
  'nesting tables',
  'nightstand',
  'ottoman',
  'outdoor',
  'pillow',
  'planter',
  'pouf',
  'recliner',
  'rug',
  'sconce',
  'sculpture',
  'sectional',
  'shelf',
  'side table',
  'sleeper',
  'sofa',
  'sofa with storage',
  'stool',
  'storage',
  'swivel chair',
  'tables',
  'throw',
  'wall art',
  'wall hanging',
  'wall unit',
  'wallpaper',
];
export const PDP_SELECTION_STORAGE = 'pdpSelect'; // Holds current user upholstery selection
export const RECENTLY_VIEWED_KEY = 'recentlyViewedIds';
export const MODULAR_ORDER_STATE = 'modOrderState';

export const FB_LOGIN_PREV_PATH = 'fbLoginPrevPath';
export const FB_LOGIN_FAVORITES = 'fbLoginFavorite';
export const FB_LOGIN_PII = 'fbLoginPii';

export const DL_UPCHARGE_PRODUCT_OPTIONS = 'dlUpChargeProductOptions';

export const ORIENTATION_PRODUCT_OPTION_ID = 16;
export const FABRIC_PRODUCT_OPTION_ID = 17;
export const LEATHER_PRODUCT_OPTION_ID = 18;
export const VENDOR_LEATHER_PRODUCT_OPTION_ID = 165;
export const WOOD_STAIN_PRODUCT_OPTION_ID = 19;
export const BED_SIZE_PRODUCT_OPTION_ID = 20;
export const WOOD_PRODUCT_OPTION_ID = 132;
export const FRAME_PRODUCT_OPTION_ID = 163;
export const SHELF_FINISH_PRODUCT_OPTION_ID = 147;
export const FINISH_PRODUCT_OPTION_ID = 164;
export const SIZE_PRODUCT_OPTION_ID = 158;
export const ORIENTATION_SINGLE_ARM_CHAISE = 89;
export const ORIENTATION_SINGLE_ARM_CHAISE_ID = 153;
export const ORIENTATION_SLEEPER = 117;
export const CHAISE_ORIENTATION_OPTION_ID = 74;
export const GLASS_PRODUCT_OPTION_ID = 149;
export const RUGS_SIZE_PRODUCT_OPTION_ID = 72;
export const WALLPAPER_COLOR_PRODUCT_OPTION_ID = 168;
export const BB_ORIENTATION_PRODUCT_OPTION_ID = 120;
export const FRAME_PRODUCT_OPTION_NAME = 'Frame';

export const FABRIC_IMG_URL = imageUrls.fabricPath;
export const PRODUCT_CONFIG_IMAGE_BASE = imageUrls.productConfigImageBaseUrl;
export const HERO_IMG_URL = imageUrls.heroImgPath;
export const { imgixDomain } = imageUrls;
export const PDP_CUSTOMIZE_TAB_ID = 'custom';
export const PDP_READYTOSHIP_TAB_ID = 'ready-to-ship';
export const PDP_DECIDELATER_TAB_ID = 'decide-later';

export const IATA_LOS_ANGELES_TIMEZONE = 'America/Los_Angeles';
export const DEFAULT_WAREHOUSE_ID = '12';

export const PDP_SELECTION_VALIDITY_STATES = {
  INVALID: 'invalid',
  INACTIVE: 'inactive',
};

export const PDP_NAMED_FILTERS = {
  FAVORITES: 'favorites',
  POPULAR: 'popular',
  VELVET: 'velvet',
  PETFRIENDLY: 'pet-friendly',
  SAFEGUARD: 'safeguard',
  SUSTAINABLE: 'sustainable',
  CLEARANCE: 'clearance',
  GREENGUARD: 'greenguard-gold',
  ALL: 'all',
};

export const PDP_EXTRA_CUSHION = 'pdpExtraCushion';

export const PRODUCT_OPTION_URL_SUFFIX = {
  [FABRIC_PRODUCT_OPTION_ID]: 'fabric',
  [LEATHER_PRODUCT_OPTION_ID]: 'leather',
  [WOOD_PRODUCT_OPTION_ID]: 'wood',
  [WOOD_STAIN_PRODUCT_OPTION_ID]: 'woodStain',
  [ORIENTATION_PRODUCT_OPTION_ID]: 'orientation',
};

export const collectionTypes = {
  fabric: {
    id: 'fabric',
    name: 'Fabric',
    pickLater: false,
    isFiltersPanelVisible: false,
  },
  leather: {
    id: 'leather',
    name: 'Leather',
    pickLater: false,
    isFiltersPanelVisible: false,
  },
  woodStain: {
    id: 'woodStain',
    name: 'Wood stain',
    isFiltersPanelVisible: false,
  },
  wood: {
    id: 'wood',
    name: 'Wood',
    isFiltersPanelVisible: false,
  },
  seatCushion: {
    id: 'seatCushion',
    name: 'Seat Cushion',
    isFiltersPanelVisible: false,
  },
  mattress: {
    id: 'mattress',
    name: 'Mattress',
    isFiltersPanelVisible: false,
  },
  rugs: {
    id: 'rugs',
    name: 'Rugs',
    isFiltersPanelVisible: false,
  },
  orientation: {
    id: 'orientation',
    name: 'Orientation',
    isFiltersPanelVisible: false,
  },
  orientationSectionalBumper: {
    id: 'orientationSectionalBumper',
    name: 'Orientation Sectional Bumper',
    isFiltersPanelVisible: false,
  },
  bedSize: {
    id: 'bedSize',
    name: 'Bed Size',
    isFiltersPanelVisible: false,
  },
  backCushion: {
    id: 'backCushion',
    name: 'Back Cushion',
  },
  yards: {
    id: 'yards',
    name: 'Yards',
  },
  deskWidth: {
    id: 'deskWidth',
    name: 'Desk Width',
  },
  deskDepth: {
    id: 'deskDepth',
    name: 'Desk Depth',
  },
  deskHeight: {
    id: 'deskHeight',
    name: 'Desk Height',
  },
  credenzaWidth: {
    id: 'credenzaWidth',
    name: 'Credenza Width',
  },
  credenzaDepth: {
    id: 'credenzaDepth',
    name: 'Credenza Depth',
  },
  credenzaHeight: {
    id: 'credenzaHeight',
    name: 'Credenza Height',
  },
  diningTableWidth: {
    id: 'diningTableWidth',
    name: 'Dining Table Width',
  },
  diningTableHeight: {
    id: 'diningTableHeight',
    name: 'Dining Table Height',
  },
  diningTableDepth: {
    id: 'diningTableDepth',
    name: 'Dining Table Depth',
  },
  sofaWidth: {
    id: 'sofaWidth',
    name: 'Sofa Width',
  },
  sofaDepth: {
    id: 'sofaDepth',
    name: 'Sofa Depth',
  },
  loveseatWidth: {
    id: 'loveseatWidth',
    name: 'Loveseat Width',
  },
  loveseatDepth: {
    id: 'loveseatDepth',
    name: 'Loveseat Depth',
  },
  sofaCard: {
    id: 'sofaCard',
    name: 'Sofa Card',
  },
  chairCard: {
    id: 'chairCard',
    name: 'Chair Card',
  },
  chairWidth: {
    id: 'chairWidth',
    name: 'Chair Width',
  },
  chairDepth: {
    id: 'chairDepth',
    name: 'Chair Depth',
  },
  ottomanWidth: {
    id: 'ottomanWidth',
    name: 'Ottoman Width',
  },
  ottomanDepth: {
    id: 'ottomanDepth',
    name: 'Ottoman Depth',
  },
  benchWidth: {
    id: 'benchWidth',
    name: 'Bench Width',
  },
  benchHeight: {
    id: 'benchHeight',
    name: 'Bench Height',
  },
  benchDepth: {
    id: 'benchDepth',
    name: 'Bench Depth',
  },
  coffeeTableWidth: {
    id: 'coffeeTableWidth',
    name: 'Coffee Table Width',
  },
  coffeeTableHeight: {
    id: 'coffeeTableHeight',
    name: 'Coffee Table Height',
  },
  coffeeTableDepth: {
    id: 'coffeeTableDepth',
    name: 'Coffee Table Depth',
  },
  endTableDepth: {
    id: 'endTableDepth',
    name: 'End Table Depth',
  },
  endTableWidth: {
    id: 'endTableWidth',
    name: 'End Table Width',
  },
  endTableHeight: {
    id: 'endTableHeight',
    name: 'End Table Height',
  },
  bedHeadboard: {
    id: 'bedHeadboard',
    name: 'Bed Headboard',
  },
  sectionalWidth: {
    id: 'sectionalWidth',
    name: 'Sectional Width',
  },
  sectionalDepth: {
    id: 'sectionalDepth',
    name: 'Sectional Depth',
  },
  pillowWidth: {
    id: 'pillowWidth',
    name: 'Pillow Width',
  },
  pillowHeight: {
    id: 'pillowHeight',
    name: 'Pillow Height',
  },
  lSectionalLongerWidth: {
    id: 'lSectionalLongerWidth',
    name: 'L Sectional Longer Width',
  },
  orientationL: {
    id: 'orientationL',
    name: 'Orientation L',
  },
  chaiseDepth: {
    id: 'chaiseDepth',
    name: 'Chaise Depth',
  },
  pillows: {
    id: 'pillows',
    name: 'Pillows',
  },
  chaiseArmFacing: {
    id: 'chaiseArmFacing',
    name: 'Chaise Arm Facing',
  },
  lSectionalShorterWidth: {
    id: 'lSectionalShorterWidth',
    name: 'L Sectional Shorter Width',
  },
  accessorySize: {
    id: 'accessorySize',
    name: 'Accessory Size',
  },
  justiceWallUnit: {
    id: 'justiceWallUnit',
    name: 'Justice Wall Unit',
  },
  specialItemUpgrade: {
    id: 'specialItemUpgrade',
    name: 'Special Item Upgrade',
  },
  uSectionalChaiseDepthLeft: {
    id: 'uSectionalChaiseDepthLeft',
    name: 'U Sectional Chaise Depth Left',
  },
  uSectionalChaiseDepthRight: {
    id: 'uSectionalChaiseDepthRight',
    name: 'U Sectional Chaise Depth Right',
  },
  diningTableDiameter: {
    id: 'diningTableDiameter',
    name: 'Dining Table Diameter',
  },
  uSectionalSofaDepthLeft: {
    id: 'uSectionalSofaDepthLeft',
    name: 'U Sectional Sofa Depth Left',
  },
  uSectionalSofaDepthRight: {
    id: 'uSectionalSofaDepthRight',
    name: 'U Sectional Sofa Depth Right',
  },
  orientationBumperChaise: {
    id: 'orientationBumperChaise',
    name: 'Orientation Bumper Chaise',
  },
  leftCornerChairWidth: {
    id: 'leftCornerChairWidth',
    name: 'Left Corner Chair Width',
  },
  rightCornerChairWidth: {
    id: 'rightCornerChairWidth',
    name: 'Right Corner Chair Width',
  },
  orientationSingleArmChaise: {
    id: 'orientationSingleArmChaise',
    name: 'Orientation Single Arm Chaise',
  },
  cornerSectionalWidthL: {
    id: 'cornerSectionalWidthL',
    name: 'Corner Sectional Width L',
  },
  cornerSectionalWidthR: {
    id: 'cornerSectionalWidthR',
    name: 'Corner Sectional Width R"',
  },
  tufting: {
    id: 'tufting',
    name: 'Tufting',
  },
  cushionQuantity: {
    id: 'cushionQuantity',
    name: 'Cushion Quantity',
  },
  pillowInsert: {
    id: 'pillowInsert',
    name: 'Pillow Insert',
  },
  orientationSleeper: {
    id: 'orientationSleeper',
    name: 'Orientation Sleeper',
  },
  coloredStains: {
    id: 'coloredStains',
    name: 'Colored Stains',
  },
  orientationSingleArmSofa: {
    id: 'orientationSingleArmSofa',
    name: 'Orientation Single Arm Sofa',
  },
  cushion: {
    id: 'cushion',
    name: 'Cushion',
  },
  orientationLouie: {
    id: 'orientationLouie',
    name: 'Orientation Louie',
  },
  orientationUSofaBumperSectional: {
    id: 'orientationUSofaBumperSectional',
    name: 'Orientation U Sofa Bumper Sectional',
  },
  roundCornerSectionalL: {
    id: 'roundCornerSectionalL',
    name: 'Round Corner Sectional L',
  },
  roundCornerSectionalR: {
    id: 'roundCornerSectionalR',
    name: 'Round Corner Sectional R',
  },
  roundCornerChairWidth: {
    id: 'roundCornerChairWidth',
    name: 'Round Corner Chair Width',
  },
  sleeperWidth: {
    id: 'sleeperWidth',
    name: 'Sleeper Width',
  },
  pipingOption: {
    id: 'pipingOption',
    name: 'Piping Option',
  },
  quantity: {
    id: 'quantity',
    name: 'Quantity',
  },
  wEWoodStain: {
    id: 'wEWoodStain',
    name: 'WE Wood Stain',
  },
  reversibleSectionalBonusCushion: {
    id: 'reversibleSectionalBonusCushion',
    name: 'Optional Cushion',
  },
  coat: {
    id: 'coat',
    name: 'Coat',
  },
  legOption: {
    id: 'legOption',
    name: 'Leg Option',
  },
  orientationDesk: {
    id: 'orientationDesk',
    name: 'Orientation Desk',
  },
  collection: {
    id: 'collection',
    name: 'Collection',
  },
  side: {
    id: 'side',
    name: 'Side',
  },
  wholesalePillowSize: {
    id: 'wholesalePillowSize',
    name: 'Wholesale Pillow size',
  },
  fSLegOption: {
    id: 'fSLegOption',
    name: 'FS Leg Option',
  },
  cubedOttoman: {
    id: 'cubedOttoman',
    name: 'Cubed Ottoman',
  },
  metalBase: {
    id: 'metalBase',
    name: 'Metal Base',
  },
  productType: {
    id: 'productType',
    name: 'Product Type',
  },
  vaseSizing: {
    id: 'vaseSizing',
    name: 'Vase Sizing',
  },
  shelfSize: {
    id: 'shelfSize',
    name: 'Shelf Size',
  },
  shelfFinish: {
    id: 'shelfFinish',
    name: 'Shelf Finish',
  },
  vendorFabric: {
    id: 'vendorFabric',
    name: 'Vendor Fabric',
  },
  glass: {
    id: 'glass',
    name: 'Glass',
  },
  testVendorFabric: {
    id: 'testVendorFabric',
    name: 'Test Vendor Fabric',
  },
  vendorFabricHF: {
    id: 'vendorFabricHF',
    name: 'Vendor Fabric HF',
  },
  vendorWoodHF: {
    id: 'vendorWoodHF',
    name: 'Vendor Wood HF',
  },
  orientationDisplaySingleArmChair: {
    id: 'orientationDisplaySingleArmChair',
    name: 'Orientation Display Single Arm Chair',
  },
  orientationDisplaySingleArmChaise: {
    id: 'orientationDisplaySingleArmChaise',
    name: 'Orientation Display Single Arm Chaise',
  },
  caseGoods: {
    id: 'caseGoods',
    name: 'Case Goods',
  },
  orientationLoungeSofa: {
    id: 'orientationLoungeSofa',
    name: 'Orientation Lounge Sofa',
  },
  size: {
    id: 'size',
    name: 'Size',
  },
  frame: {
    id: 'frame',
    name: 'Frame',
  },
  vendorLeather: {
    id: 'vendorLeather',
    name: 'Leather',
  },
};

export const collectionTypesMapping = {
  16: collectionTypes.orientation.id,
  120: collectionTypes.orientationSectionalBumper.id,
  17: collectionTypes.fabric.id,
  18: collectionTypes.leather.id,
  19: collectionTypes.woodStain.id,
  132: collectionTypes.wood.id,
  21: collectionTypes.seatCushion.id,
  93: collectionTypes.mattress.id,
  72: collectionTypes.rugs.id,
  20: collectionTypes.bedSize.id,
  22: collectionTypes.backCushion.id,
  24: collectionTypes.yards.id,
  27: collectionTypes.deskWidth.id,
  28: collectionTypes.deskDepth.id,
  29: collectionTypes.deskHeight.id,
  30: collectionTypes.credenzaWidth.id,
  31: collectionTypes.credenzaDepth.id,
  32: collectionTypes.credenzaHeight.id,
  33: collectionTypes.diningTableWidth.id,
  34: collectionTypes.diningTableHeight.id,
  35: collectionTypes.diningTableDepth.id,
  36: collectionTypes.sofaWidth.id,
  39: collectionTypes.sofaDepth.id,
  40: collectionTypes.loveseatWidth.id,
  43: collectionTypes.loveseatDepth.id,
  45: collectionTypes.sofaCard.id,
  46: collectionTypes.chairCard.id,
  47: collectionTypes.chairWidth.id,
  48: collectionTypes.chairDepth.id,
  50: collectionTypes.ottomanWidth.id,
  52: collectionTypes.ottomanDepth.id,
  53: collectionTypes.benchWidth.id,
  54: collectionTypes.benchHeight.id,
  55: collectionTypes.benchDepth.id,
  56: collectionTypes.coffeeTableWidth.id,
  57: collectionTypes.coffeeTableHeight.id,
  58: collectionTypes.coffeeTableDepth.id,
  59: collectionTypes.endTableDepth.id,
  60: collectionTypes.endTableWidth.id,
  61: collectionTypes.endTableHeight.id,
  62: collectionTypes.bedHeadboard.id,
  63: collectionTypes.sectionalWidth.id,
  64: collectionTypes.sectionalDepth.id,
  66: collectionTypes.pillowWidth.id,
  67: collectionTypes.pillowHeight.id,
  68: collectionTypes.lSectionalLongerWidth.id,
  70: collectionTypes.orientationL.id,
  71: collectionTypes.chaiseDepth.id,
  73: collectionTypes.pillows.id,
  74: collectionTypes.chaiseArmFacing.id,
  75: collectionTypes.lSectionalShorterWidth.id,
  76: collectionTypes.accessorySize.id,
  77: collectionTypes.justiceWallUnit.id,
  78: collectionTypes.specialItemUpgrade.id,
  79: collectionTypes.uSectionalChaiseDepthLeft.id,
  80: collectionTypes.uSectionalChaiseDepthRight.id,
  81: collectionTypes.diningTableDiameter.id,
  83: collectionTypes.uSectionalSofaDepthLeft.id,
  84: collectionTypes.uSectionalSofaDepthRight.id,
  85: collectionTypes.orientationBumperChaise.id,
  87: collectionTypes.leftCornerChairWidth.id,
  88: collectionTypes.rightCornerChairWidth.id,
  89: collectionTypes.orientationSingleArmChaise.id,
  90: collectionTypes.cornerSectionalWidthL.id,
  91: collectionTypes.cornerSectionalWidthR.id,
  94: collectionTypes.tufting.id,
  95: collectionTypes.cushionQuantity.id,
  103: collectionTypes.pillowInsert.id,
  117: collectionTypes.orientationSleeper.id,
  118: collectionTypes.coloredStains.id,
  119: collectionTypes.orientationSingleArmSofa.id,
  121: collectionTypes.cushion.id,
  122: collectionTypes.orientationLouie.id,
  123: collectionTypes.orientationUSofaBumperSectional.id,
  124: collectionTypes.roundCornerSectionalL.id,
  125: collectionTypes.roundCornerSectionalR.id,
  126: collectionTypes.roundCornerChairWidth.id,
  127: collectionTypes.sleeperWidth.id,
  128: collectionTypes.pipingOption.id,
  129: collectionTypes.quantity.id,
  131: collectionTypes.wEWoodStain.id,
  133: collectionTypes.reversibleSectionalBonusCushion.id,
  134: collectionTypes.coat.id,
  135: collectionTypes.legOption.id,
  136: collectionTypes.orientationDesk.id,
  137: collectionTypes.collection.id,
  139: collectionTypes.side.id,
  140: collectionTypes.wholesalePillowSize.id,
  141: collectionTypes.fSLegOption.id,
  142: collectionTypes.cubedOttoman.id,
  143: collectionTypes.metalBase.id,
  144: collectionTypes.productType.id,
  145: collectionTypes.vaseSizing.id,
  146: collectionTypes.shelfSize.id,
  147: collectionTypes.shelfFinish.id,
  148: collectionTypes.vendorFabric.id,
  149: collectionTypes.glass.id,
  150: collectionTypes.testVendorFabric.id,
  151: collectionTypes.vendorFabricHF.id,
  152: collectionTypes.vendorWoodHF.id,
  153: collectionTypes.orientationDisplaySingleArmChair.id,
  154: collectionTypes.orientationDisplaySingleArmChaise.id,
  157: collectionTypes.caseGoods.id,
  158: collectionTypes.size.id,
  162: collectionTypes.orientationLoungeSofa.id,
  163: collectionTypes.frame.id,
  165: collectionTypes.vendorLeather.id,
};

export const optionIdMapping = {
  [collectionTypes.orientation.id]: 16,
  [collectionTypes.orientationSectionalBumper.id]: 120,
  [collectionTypes.fabric.id]: 17,
  [collectionTypes.leather.id]: 18,
  [collectionTypes.woodStain.id]: 19,
  [collectionTypes.wood.id]: 132,
  [collectionTypes.seatCushion.id]: 21,
  [collectionTypes.mattress.id]: 93,
  [collectionTypes.rugs.id]: 72,
  [collectionTypes.bedSize.id]: 20,
  [collectionTypes.backCushion.id]: 22,
  [collectionTypes.yards.id]: 24,
  [collectionTypes.deskWidth.id]: 27,
  [collectionTypes.deskDepth.id]: 28,
  [collectionTypes.deskHeight.id]: 29,
  [collectionTypes.credenzaWidth.id]: 30,
  [collectionTypes.credenzaDepth.id]: 31,
  [collectionTypes.credenzaHeight.id]: 32,
  [collectionTypes.diningTableWidth.id]: 33,
  [collectionTypes.diningTableHeight.id]: 34,
  [collectionTypes.diningTableDepth.id]: 35,
  [collectionTypes.sofaWidth.id]: 36,
  [collectionTypes.sofaDepth.id]: 39,
  [collectionTypes.loveseatWidth.id]: 40,
  [collectionTypes.loveseatDepth.id]: 43,
  [collectionTypes.sofaCard.id]: 45,
  [collectionTypes.chairCard.id]: 46,
  [collectionTypes.chairWidth.id]: 47,
  [collectionTypes.chairDepth.id]: 48,
  [collectionTypes.ottomanWidth.id]: 50,
  [collectionTypes.ottomanDepth.id]: 52,
  [collectionTypes.benchWidth.id]: 53,
  [collectionTypes.benchHeight.id]: 54,
  [collectionTypes.benchDepth.id]: 55,
  [collectionTypes.coffeeTableWidth.id]: 56,
  [collectionTypes.coffeeTableHeight.id]: 57,
  [collectionTypes.coffeeTableDepth.id]: 58,
  [collectionTypes.endTableDepth.id]: 59,
  [collectionTypes.endTableWidth.id]: 60,
  [collectionTypes.endTableHeight.id]: 61,
  [collectionTypes.bedHeadboard.id]: 62,
  [collectionTypes.sectionalWidth.id]: 63,
  [collectionTypes.sectionalDepth.id]: 64,
  [collectionTypes.pillowWidth.id]: 66,
  [collectionTypes.pillowHeight.id]: 67,
  [collectionTypes.lSectionalLongerWidth.id]: 68,
  [collectionTypes.orientationL.id]: 70,
  [collectionTypes.chaiseDepth.id]: 71,
  [collectionTypes.pillows.id]: 73,
  [collectionTypes.chaiseArmFacing.id]: 74,
  [collectionTypes.lSectionalShorterWidth.id]: 75,
  [collectionTypes.accessorySize.id]: 76,
  [collectionTypes.justiceWallUnit.id]: 77,
  [collectionTypes.specialItemUpgrade.id]: 78,
  [collectionTypes.uSectionalChaiseDepthLeft.id]: 79,
  [collectionTypes.uSectionalChaiseDepthRight.id]: 80,
  [collectionTypes.diningTableDiameter.id]: 81,
  [collectionTypes.uSectionalSofaDepthLeft.id]: 83,
  [collectionTypes.uSectionalSofaDepthRight.id]: 84,
  [collectionTypes.orientationBumperChaise.id]: 85,
  [collectionTypes.leftCornerChairWidth.id]: 87,
  [collectionTypes.rightCornerChairWidth.id]: 88,
  [collectionTypes.orientationSingleArmChaise.id]: 89,
  [collectionTypes.cornerSectionalWidthL.id]: 90,
  [collectionTypes.cornerSectionalWidthR.id]: 91,
  [collectionTypes.tufting.id]: 94,
  [collectionTypes.cushionQuantity.id]: 95,
  [collectionTypes.pillowInsert.id]: 103,
  [collectionTypes.orientationSleeper.id]: 117,
  [collectionTypes.coloredStains.id]: 118,
  [collectionTypes.orientationSingleArmSofa.id]: 119,
  [collectionTypes.cushion.id]: 121,
  [collectionTypes.orientationLouie.id]: 122,
  [collectionTypes.orientationUSofaBumperSectional.id]: 123,
  [collectionTypes.roundCornerSectionalL.id]: 124,
  [collectionTypes.roundCornerSectionalR.id]: 125,
  [collectionTypes.roundCornerChairWidth.id]: 126,
  [collectionTypes.sleeperWidth.id]: 127,
  [collectionTypes.pipingOption.id]: 128,
  [collectionTypes.quantity.id]: 129,
  [collectionTypes.wEWoodStain.id]: 131,
  [collectionTypes.reversibleSectionalBonusCushion.id]: 133,
  [collectionTypes.coat.id]: 134,
  [collectionTypes.legOption.id]: 135,
  [collectionTypes.orientationDesk.id]: 136,
  [collectionTypes.collection.id]: 137,
  [collectionTypes.side.id]: 139,
  [collectionTypes.wholesalePillowSize.id]: 140,
  [collectionTypes.fSLegOption.id]: 141,
  [collectionTypes.cubedOttoman.id]: 142,
  [collectionTypes.metalBase.id]: 143,
  [collectionTypes.productType.id]: 144,
  [collectionTypes.vaseSizing.id]: 145,
  [collectionTypes.shelfSize.id]: 146,
  [collectionTypes.shelfFinish.id]: 147,
  [collectionTypes.vendorFabric.id]: 148,
  [collectionTypes.glass.id]: 149,
  [collectionTypes.testVendorFabric.id]: 150,
  [collectionTypes.vendorFabricHF.id]: 151,
  [collectionTypes.vendorWoodHF.id]: 152,
  [collectionTypes.orientationDisplaySingleArmChair.id]: 153,
  [collectionTypes.orientationDisplaySingleArmChaise.id]: 154,
  [collectionTypes.caseGoods.id]: 157,
  [collectionTypes.size.id]: 158,
  [collectionTypes.orientationLoungeSofa.id]: 162,
  [collectionTypes.frame.id]: 163,
  [collectionTypes.vendorLeather.id]: 165,
};

export const isOptionMaterial = {
  [optionIdMapping.fabric]: true,
  [optionIdMapping.leather]: true,
};

export const orientationTypeOptions = {
  [collectionTypes.orientation.id]: collectionTypes.orientation.id,
  [collectionTypes.orientationSectionalBumper.id]:
    collectionTypes.orientationSectionalBumper.id,
  [collectionTypes.orientationL.id]: collectionTypes.orientationL.id,
  [collectionTypes.orientationBumperChaise.id]:
    collectionTypes.orientationBumperChaise.id,
  [collectionTypes.orientationSingleArmChaise.id]:
    collectionTypes.orientationSingleArmChaise.id,
  [collectionTypes.orientationSleeper.id]:
    collectionTypes.orientationSleeper.id,
  [collectionTypes.orientationSingleArmSofa.id]:
    collectionTypes.orientationSingleArmSofa.id,
  [collectionTypes.orientationLouie.id]: collectionTypes.orientationLouie.id,
  [collectionTypes.orientationUSofaBumperSectional.id]:
    collectionTypes.orientationUSofaBumperSectional.id,
  [collectionTypes.orientationDesk.id]: collectionTypes.orientationDesk.id,
  [collectionTypes.orientationDisplaySingleArmChair.id]:
    collectionTypes.orientationDisplaySingleArmChair.id,
  [collectionTypes.orientationDisplaySingleArmChaise.id]:
    collectionTypes.orientationDisplaySingleArmChaise.id,
  [collectionTypes.chaiseArmFacing.id]: collectionTypes.chaiseArmFacing.id,
  [collectionTypes.orientationLoungeSofa.id]:
    collectionTypes.orientationLoungeSofa.id,
};

export const ORIENTATION_TYPE_OPTION_IDS = [
  16,
  120,
  70,
  74,
  85,
  89,
  117,
  119,
  122,
  123,
  136,
  153,
  154,
  162,
];

export const BUILD_YOUR_OWN_SOFA_LINK =
  'https://joybird2.imgix.net/build-your-own-sofa.png?';

export const COMPONENT_CALLER_TYPE = {
  PRODUCT_CARD: 'ProductCard',
  PDP: 'PDP',
};

export const ALGOLIA_INDEX_NAME = {
  PRODUCTS: 'products_jb4',
  CUSTOMER_PHOTOS: 'customer_photos',
  REVIEWS: 'reviews',
  STATIC_PAGES: 'static_pages_new',
  CATEGORIES: 'categories',
  PRODUCTS_PRICE_ASC: 'products_jb4_price_asc',
  PRODUCTS_PRICE_DESC: 'products_jb4_price_desc',
  STATIC_PAGES_JB4: 'static_pages_jb4',
};

export const VALID_IMAGE_OPTION_IDS = [
  FABRIC_PRODUCT_OPTION_ID,
  LEATHER_PRODUCT_OPTION_ID,
  WOOD_STAIN_PRODUCT_OPTION_ID,
  WOOD_PRODUCT_OPTION_ID,
  SHELF_FINISH_PRODUCT_OPTION_ID,
  FRAME_PRODUCT_OPTION_ID,
];

export const VALID_WOOD_OPTION_IDS = [
  WOOD_STAIN_PRODUCT_OPTION_ID,
  WOOD_PRODUCT_OPTION_ID,
];

export const VALID_HERO_DISPLAY_PRODUCT_OPTIONS = [
  FABRIC_PRODUCT_OPTION_ID,
  LEATHER_PRODUCT_OPTION_ID,
  WOOD_STAIN_PRODUCT_OPTION_ID,
  WOOD_PRODUCT_OPTION_ID,
  SHELF_FINISH_PRODUCT_OPTION_ID,
  FRAME_PRODUCT_OPTION_ID,
  FINISH_PRODUCT_OPTION_ID,
  SIZE_PRODUCT_OPTION_ID,
  GLASS_PRODUCT_OPTION_ID,
];

export const STOCK_VALUE_STATES = {
  LOADING: 'LOADING',
  INSTOCK: 'INSTOCK',
  OOS: 'OOS',
  DISCONTINUED: 'DISCONTINUED',
};

export const INVALID_NAME_CHARACTERS_REGEX = /[`|~|!|@|#|$|%|^|&|*|(|)|+|=|[|{|\]|}|||\\|<|>|?|/|""|;|:]/;

export const VALID_LINK_REGEX = /^(?:(?:https?):\/\/)(?::[A-Za-z]*)?(?:(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

export const ROUTE_ERROR_DATA_UPDATED = 'ROUTE_ERROR_DATA_UPDATED';

export const CYLINDO_ACCOUNT_ID = 5022;
export const CYLINDO_OPTIONS = {
  UPHOLSTERY: 'UPHOLSTERY',
  ORIENTATION: 'ORIENTATION',
};

export const NUMBER_OF_PRODUCTS_TO_SHOW = 12;

export const NO_REVIEW_AVAILABLE = `No Review(s) Available`;

export const NUMBER_OF_SWATCHES_TO_SHOW = 6;

export const DEFAULT_SORT_ORDER = 'featured';

export const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  // TODO: Re-enable this in Category Page Phase 2, in CON-4294
  // { value: 'popularity', label: 'Popularity' },
  { value: 'newest', label: 'New Arrivals' },
  { value: 'priceLowToHigh', label: 'Price - Low to High' },
  { value: 'priceHighToLow', label: 'Price - High to Low' },
  { value: 'alphabetical', label: 'Name - A to Z' },
];

export const PRODUCT_LISTING_MAX_FILTER_OPTIONS = 10;

export const PRODUCT_GRID_DATA_XC_ID = 'product-grid';

export const DEFAULT_IMAGE_HOST = 'https://joybird2.imgix.net';

export const GREENGUARD_FABRICS = [
  'Washed California',
  'Villa',
  'Tussah',
  'Sorrento',
  'Royale',
  'Prime',
  'Nico',
  'Merit',
  'Marin',
  'Essence',
  'Milo',
  'Dawson',
  'Bungalow',
  'Bentley',
];

export const LABEL_MAP = { Fabrics: 'Fabrics by the yard' };

export const OVERRIDE_LINKS = {
  Notch: 'https://modular.joybird.com/?categoryid=12256',
  Bryant: 'https://modular.joybird.com/?categoryid=12230',
  Logan: 'https://modular.joybird.com/?categoryid=12260',
  Holt: 'https://modular.joybird.com/?categoryid=12259',
  'Holt Petite': 'https://modular.joybird.com/?categoryid=20262',
  Haine: 'https://modular.joybird.com/?categoryid=12258',
  Matias: 'https://modular.joybird.com/?categoryid=12261',
  Daya: 'https://modular.joybird.com/?categoryid=12257',
  Antony: 'https://modular.joybird.com/?categoryid=13707',
  Diane: 'https://modular.joybird.com/?categoryid=17045',
  Sebastian: 'https://modular.joybird.com/?categoryid=17406',
  'Bryant Petite': 'https://modular.joybird.com/?categoryid=15076',
  'Bryant Slipcovered': 'https://modular.joybird.com/?categoryid=20752',
};

export const COLOR_CODES_FABRIC = [
  { white: '#fff' },
  { black: '#484848' },
  { 'beige/white': '#EEEFE9' },
  { brown: '#7B655F' },
  { blue: '#5B86C5' },
  { orange: '#F6A16A' },
  { purple: '#6F598B' },
  { gray: '#A5A7A9' },
  { red: '#B85B64' },
  { pink: '#FFCEE2' },
  { green: '#5C9B93' },
  { yellow: '#f5DA9F' },
];

export const FABRIC_COLOR_CODES_MAP = {
  white: '#fff',
  black: '#484848',
  'beige/white': '#EEEFE9',
  brown: '#7B655F',
  blue: '#5B86C5',
  orange: '#F6A16A',
  purple: '#6F598B',
  gray: '#A5A7A9',
  red: '#B85B64',
  pink: '#FFCEE2',
  green: '#5C9B93',
  yellow: '#f5DA9F',
};

export const COLOR_CODES_WOOD = [
  { natural: '#b77a3b' },
  { medium: '#833d19' },
  { walnut: '#4a1b07' },
  { coffee_bean: '#4b2e1e' },
  { wood_black: '#151515' },
  { wood_ash: '' },
];

export const MATERIAL_CODES = [
  { fabric: 'Fabric' },
  { leather: 'Leather' },
  { wood: 'Wood' },
];

export const MATERIAL_OPTIONS = [
  {
    value: 'fabric',
    label: 'Fabric',
  },
  {
    value: 'leather',
    label: 'Leather',
  },
  {
    value: 'wood',
    label: 'Wood',
  },
];

export const STYLE_OPTIONS = [
  {
    value: 'midcentury',
    label: 'Mid-Century',
  },
  {
    value: 'transitional',
    label: 'Transitional',
  },
  {
    value: 'modern',
    label: 'Modern',
  },
];

export const LEG_STYLE_OPTIONS = [
  {
    value: 'Straight',
    label: 'Straight',
  },
  {
    value: 'Flared',
    label: 'Flared',
  },
  {
    value: 'Ball',
    label: 'Ball',
  },
  {
    value: 'Square',
    label: 'Square',
  },
  {
    value: 'Base',
    label: 'Base',
  },
  {
    value: 'Peg',
    label: 'Peg',
  },
];

export const CUSHION_STYLE_OPTIONS = [
  {
    value: 'attached',
    label: 'Attached',
  },
  {
    value: 'removable',
    label: 'Removable',
  },
  {
    value: 'tufted',
    label: 'Tufted',
  },
  {
    value: 'downalt',
    label: 'Down Alternative',
  },
];

export const PHOTOS_FROM_OPTIONS = [
  {
    value: 'everyone',
    label: 'Everyone',
  },
  {
    value: 'joybird',
    label: 'Joybird',
  },
  {
    value: 'customers',
    label: 'Customers',
  },
];

export const ROOM_OPTIONS = [
  {
    value: 'Living Room',
    label: 'Living Room',
  },
  {
    value: 'Dining Room',
    label: 'Dining Room',
  },
  {
    value: 'Bedroom',
    label: 'Bedroom',
  },
  {
    value: 'Home Office',
    label: 'Home Office',
  },
  {
    value: 'Outdoor Room',
    label: 'Outdoor',
  },
];

export const EXTERNAL_URL_REGEX = /^(http[s]?:\/\/)|^(mailto:)/;

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_REGEX = /^\(([0-9]{3})\)[ ]([0-9]{3})[-]([0-9]{4})$|^\d{10}$/;

export const ZIP_CODE_REGEX = /^\d{5}$/;

export const RECAPTCHA_SITE_KEY = '6LezxVUUAAAAAB71O109y_4jXnwSx-nsJWT_kQz_';

export const ZIPCODE_ERROR_MESSAGES = {
  ZIPCODE_IS_EMPTY: 'Zip code cannot be empty',
  ZIPCODE_IS_INVALID_FORMAT: 'Please enter a valid zip code',
  ZIPCODE_NOT_FOUND: 'Sorry, this zip code cannot be found',
  UNEXPECTED_ERROR:
    'Sorry, an unexpected error occurred. Please try again later.',
};

export const USER_LOCATION_COOKIE_KEY = 'jbUL';
export const ZIPCODE_FROM_USER_COOKIE_KEY = 'jbUZC';
export const CJE_EVENT_COOKIE_KEY = 'cje';

// these are all the skus that is related to product orientation
export const IMAGE_FLIP_SKUS = [
  'laf',
  'LAF',
  'SACAFL',
  'LS',
  'LFB',
  'SCHL',
  'SCHSL',
];

export const UPHOLSTERY_OPTION_IDS = [
  FABRIC_PRODUCT_OPTION_ID,
  LEATHER_PRODUCT_OPTION_ID,
];

export const getVariantsInOrder = [
  'faithful-olive',
  'essence-ash',
  'faithful-indigo',
  'milo-french-blue',
  'key-largo-zenith-teal',
  'nico-oyster',
  'synergy-pewter',
  'milo-dove',
  'borough-cotton',
  'faithful-mocha',
];

import React from 'react';
import PropTypes from 'prop-types'

const IconBell = props => {
  const color = props.color || '#585858';
  return (
    <svg width={28} height={21} {...props}>
      <g
        stroke={color}
        strokeWidth={1.5}
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="bevel"
      >
        <path d="M1.714 14.071c0-6.864 5.564-9.429 12.429-9.429 6.864 0 12.428 2.565 12.428 9.43M1 14.5h26.285M14.143 4.643V1.5M10.429 1.5h7.428M26.643 14.5v3a2 2 0 01-2 2h-21a2 2 0 01-2-2v-3" />
      </g>
    </svg>
  );
};

IconBell.propTypes = {
  color: PropTypes.string
}

export default IconBell;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ExpandableItemsList = ({ children, initialNumberToShow = 7 }) => {
  const [isOpen, setIsOpen] = useState(false);
  const childArray = React.Children.toArray(children);
  const totalNumberOfItems = childArray.length;
  const isExpandable = totalNumberOfItems > initialNumberToShow;

  return (
    <>
      {isExpandable && !isOpen
        ? childArray.slice(0, initialNumberToShow)
        : childArray}

      {isExpandable && (
        <button
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className="text-base text-gray mt-2 hover:text-gray-dark"
        >
          {!isOpen ? '+ More' : '- Less'}
        </button>
      )}
    </>
  );
};

ExpandableItemsList.propTypes = {
  children: PropTypes.node,
  initialNumberToShow: PropTypes.number,
};

export default ExpandableItemsList;

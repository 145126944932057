import find from 'lodash/find';
import buildFinalHeroImageURL from './buildHeroImageURL';
import getDefaultOptionData from './getDefaultOptionData';

function constructHeroImageURL(
    product,
    sku,
    count = 1,
    forCategory = false
  ) {
    let fabricSku = find(product.options, { name: 'Fabric' });
    const leatherSku = find(product.options, { name: 'Leather' });
    const woodSku = find(product.options, { name: 'Wood' });
  
    if (fabricSku && fabricSku.id && fabricSku.id !== 17) {
      // Handle Vendor Fabric
      fabricSku = null;
    }
  
    let { optionName, finalSku } =
      product?.options?.length && !sku
        ? getDefaultOptionData(product)
        : { optionName: '', finalSku: '' };
  
    if (sku) {
      finalSku = sku;
      const optionSkus = sku.split('-').filter(sk => !!sk);
      // Pick option name for sku
      if (fabricSku) {
        const fabricOptions = find(product.options, { name: 'Fabric' });
        optionName = find(fabricOptions.values[0].option_values, {
          sku: `-${optionSkus[0]}`,
        });
        optionName = optionName ? optionName.value : '';
      } else if (leatherSku) {
        const leatherOptions = find(product.options, { name: 'Leather' });
        optionName = find(leatherOptions.values[0].option_values, {
          sku: `-${optionSkus[0]}`,
        });
        optionName = optionName ? optionName.value : '';
      } else if (woodSku && !fabricSku && !leatherSku) {
        optionName = '';
      }
    }
  
    return buildFinalHeroImageURL(
      product.name,
      product.id,
      finalSku,
      optionName,
      count,
      forCategory
    );
  }

export default constructHeroImageURL;

import React from 'react';

const IconTick = props => (
  <svg
    focusable="false"
    viewBox="0 0 24 24"
    aria-hidden="true"
    role="presentation"
    {...props}
  >
    <path
      d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
      stroke="#fff"
      fill="#fff"
    />
  </svg>
);

export default IconTick;

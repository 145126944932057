import { gql } from '@apollo/client';

export const GET_ROUTES_QUERY = gql`
  query {
    routes: getRoutes {
      id
      title
      route
      type
      defaultVariantId
      seoconfig {
        title
        description
        keywords
        sharetitle
        sharedescription
        shareimage
        sharetype
        jsonLD
        robots_noindex
      }
      variants {
        id
        name
        audience_id
        layout_id
        components {
          component
          config
        }
      }
      dynamicVariants {
        dynamicKey
        variant {
          id
          name
          audience_id
          layout_id
          components {
            component
            config
          }
        }
      }
    }
  }
`;

const getEnvValue = name => {
  let _env_ = null;
  if (typeof window !== 'undefined') {
    _env_ = window._env_;
  }
  if (!_env_ && typeof global !== 'undefined') {
    _env_ = global._env_;
  }
  if (!_env_) {
    return null;
  }
  return _env_[name];
};

export default getEnvValue;

import React from 'react';
import PropTypes from 'prop-types';

const IconQuickship = ({ color = '#585858', ...otherProps }) => (
  <svg width={29} height={19} viewBox="0 0 29 19" {...otherProps}>
    <g stroke={color} fill="none">
      <path d="M8.75 13.791h-8M8.75 10.791h-6M8.75 7.791h-3M27.813 13.416l-7.531 4.125-7.438-4.156-.063-8.344L20.313.853l7.5 4z" />
      <path d="M12.875 5.479l7.469 4.03 7.469-3.936M20.344 9.51v8.031M16.241 7.129l7.884-4.433" />
    </g>
  </svg>
);

IconQuickship.propTypes = {
  color: PropTypes.string,
};

export default IconQuickship;

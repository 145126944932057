import React from 'react';
import PropTypes from 'prop-types';

import { colorGrayLight1 } from 'styles/layout/colors';

const IconMobileGrid1x = ({ color = colorGrayLight1, ...otherProps }) => (
  <svg width="22px" height="29px" viewBox="0 0 22 29" {...otherProps}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-338.000000, -165.000000)">
        <g transform="translate(338.000000, 165.000000)">
          <path
            d="M0.75,23.75 L21.25,23.75 L21.25,4.75 L0.75,4.75 L0.75,23.75 Z"
            stroke={color}
            strokeWidth="1.5"
          />
          <polygon fill={color} points="0 1.5 22 1.5 22 0 0 0" />
          <polygon fill={color} points="0 28.5 22 28.5 22 27 0 27" />
        </g>
      </g>
    </g>
  </svg>
);

IconMobileGrid1x.propTypes = {
  color: PropTypes.string,
};

export default IconMobileGrid1x;

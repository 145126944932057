import httpContext from 'express-http-context';

const getHttpContextValue = (name, defaultValue) => {
  if (httpContext) {
    return httpContext.get(name) || defaultValue;
  }
  return defaultValue;
};

export default getHttpContextValue;

// Note: please keep this file in sync with the variables set in tailwind.connfig.js

export const colorBlack = '#000000';
export const colorWhite = '#ffffff';

export const colorBlackOverlay = 'rgba(0, 0, 0, 0.6)';
export const colorGrayOverlay = 'rgba(38, 38, 38, 0.4)';

export const colorGrayDark = '#262626';
export const colorGray = '#424242';
export const colorGrayLight1 = '#5a5a5a';
export const colorGrayLight2 = '#737373';
export const colorGrayLight3 = '#919191';
export const colorGrayLight4 = '#cccccc';
export const colorGrayLight5 = '#e5e5e5';
export const colorGrayLight6 = '#f1f1f1';
export const colorGrayLight7 = '#f6f6f6';
export const colorGrayLight8 = '#fafafa';

export const colorBrandDarker = '#0a545f';
export const colorBrandDark = '#016a78';
export const colorBrand = '#107c8c';
export const colorBrandLight = '#cff0fc';
export const colorBrandLighter = '#eaf3f4';
export const colorBrandLightest = '#f2f8f9';

export const colorRedDark = '#832829';
export const colorRed = '#b85455';
export const colorRedLight = '#f7f1f2';

export const colorYellowDark = '#ab8d40';
export const colorYellow = '#e4c272';
export const colorYellowLight = '#f7f2e8';

export const colorFacebook = '#1977f2';

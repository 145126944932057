import React from 'react';
import PropTypes from 'prop-types';

import useRandomId from 'hooks/useRandomId';

const IconClose = ({ color = '#000', ...otherProps }) => {
  const titleId = useRandomId('IconClose-title');

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <svg
      width={14}
      height={14}
      role="img"
      viewBox="0 0 14 14"
      aria-labelledby={titleId}
      {...otherProps}
    >
      <title id={titleId}>Close</title>
      <path
        d="M14 1.41L12.59 0 7 5.59 1.41 0 0 1.41 5.59 7 0 12.59 1.41 14 7 8.41 12.59 14 14 12.59 8.41 7z"
        fill={color}
        fillRule="nonzero"
      />
    </svg>
  );
};

IconClose.propTypes = {
  color: PropTypes.string,
};

export default IconClose;

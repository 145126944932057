import React from 'react';
import PropTypes from 'prop-types'

const IconCheck = props => {
  const color = props.color || '#585858';
  return (
    <svg width={12} height={9} viewBox="0 0 12 9" {...props}>
      <path
        stroke={color}
        fill={color}
        d="M1.753 4.75l2.534 2.24L10.193 1l.807.723L4.314 8.5 1 5.514z"
        fillRule="evenodd"
      />
    </svg>
  );
};

IconCheck.propTypes = {
  color: PropTypes.string
}

export default IconCheck;

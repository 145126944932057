import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { connectStateResults } from 'react-instantsearch-dom';
import avo from 'analytics/avo';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';

const getAdvancedSearchUrlForQueryAndIndex = (
  query = '',
  index = 'products_jb4',
  facetVal,
  colorFilters = []
) => {
  const colorFiltersURIComponent =
    colorFilters.length > 0
      ? colorFilters.reduce(
          (colorQuery, colorValue) =>
            `${colorQuery}&color=${encodeURIComponent(colorValue)}`,
          ''
        )
      : '';
  const categoryName = encodeURIComponent(
    index.indexOf('products') !== -1 ? 'products_jb4' : index
  );
  const filtersApplied = encodeURIComponent(facetVal || '*');

  const queryString = encodeURIComponent(query) || '';
  return `/search/?category=${categoryName}${colorFiltersURIComponent}&q=${queryString}&filter=${filtersApplied}`;
};

const SeeFullResultsButton = connectStateResults(
  ({ searchResults, variant = 'desktop', toggleSearchOpen, colorFilters }) => {
    if (!searchResults || !searchResults.query) {
      return null;
    }
    const currentPage = useUserCurrentPageType();
    return (
      <Link
        onClick={async () => {
          if (toggleSearchOpen) await toggleSearchOpen();

          avo.productsSearched({
            query: searchResults.query,
            location: currentPage,
            searchTarget: avo.SearchTarget.FULL_RESULTS,
          });
        }}
        to={getAdvancedSearchUrlForQueryAndIndex(
          searchResults.query,
          'products',
          undefined,
          colorFilters
        )}
      >
        <div
          className={classNames(
            'p-2.5 border-solid border-gray cursor-pointer font-bold tracking-[-.0125rem] leading-[1.45rem] text-base bg-gray',
            {
              'text-center w-[200px] h-[50px] border-2 text-white':
                variant === 'desktop',
              'text-left w-auto h-auto pt-0 pr-0 pb-0 pl-2.5 border-0 bg-transparent text-gray mr-5':
                variant === 'mobile',
            }
          )}
        >
          See full results ({searchResults.nbHits})
        </div>
      </Link>
    );
  }
);

export default SeeFullResultsButton;

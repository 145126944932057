import pathLib from 'path';
import addDomainToPath from './addDomainToPath';
import { HERO_IMG_URL, PRODUCT_CONFIG_IMAGE_BASE } from './constants';

const constructProductSKUImgPrefix = (productId, sku, type, path) => {
  if (type && type === 'hero') {
    let theUrl = `${HERO_IMG_URL}${productId}/`;
    if (sku) {
      theUrl += sku;
    }
    return addDomainToPath(pathLib.join(theUrl, path));
  }
  return addDomainToPath(
    pathLib.join(`${PRODUCT_CONFIG_IMAGE_BASE}${productId}`, sku, path)
  );
};

export default constructProductSKUImgPrefix;

import constructProductSKUImgPrefix from 'commons/constructProductSKUImgPrefix';

/**
 *  Arguments
 *  productId : Product ID
 *  sku : SKU value of options
 *  productName : Product Name
 *  optionValue : Option Value / Name
 *  count : Angle
 *  type : Used by constructHeroImageURL to use different base URL,
 *  forCategory : Detect if called from Category page to use _ts format
 */
function constructImageURLsForVariant(
  productId,
  sku,
  productName,
  optionValue,
  count = 1,
  type = '',
  forCategory = false
) {
  const config_operator = '-';
  const config_image = constructProductSKUImgPrefix(
    productId,
    sku,
    type,
    encodeURIComponent(`${productId}${sku}${config_operator}${count}.jpg`)
  );
  const finalProductName = productName
    .trim()
    .toLowerCase()
    .split(' ')
    .filter(w => !!w)
    .join('-');
  const optValue = optionValue
    ? `-${optionValue.trim().toLowerCase().split(' ').join('-')}`
    : '';
  let transparent_config_image = productName
    ? constructProductSKUImgPrefix(
        productId,
        sku,
        type,
        encodeURIComponent(
          `${productId}${sku}-${finalProductName}${optValue}-t1-${count}_t`
        )
      )
    : null;

  if ((productName || '').includes('&')) {
    const encodedProductName = encodeURIComponent(finalProductName);
    transparent_config_image = transparent_config_image.replace(
      encodedProductName,
      finalProductName
    );
  }

  if (transparent_config_image.includes('(')) {
    const encodedURL = transparent_config_image.split('/');
    let lastSection = encodedURL.pop();
    lastSection = lastSection.replace('(', '%28');
    lastSection = lastSection.replace(')', '%29');
    encodedURL.push(lastSection);
    transparent_config_image = encodedURL.join('/');
  }

  let transparent_config_image_ts = null;
  if (forCategory && transparent_config_image) {
    transparent_config_image_ts = `${transparent_config_image}s.png`;
  }
  if (transparent_config_image) {
    transparent_config_image += `.png`;
  }
  return {
    config_image,
    transparent_config_image,
    transparent_config_image_ts,
  };
}

export default constructImageURLsForVariant;

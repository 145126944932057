import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { gql, useLazyQuery } from '@apollo/client';

import publicImgBaseURL from 'commons/publicImgBaseURL';
import getProductImageUrl from 'commons/getProductImageUrl';
import getVisitorLocationQueryVariables from 'commons/getVisitorLocationQueryVariables';
import useStoreDetails from 'hooks/useStoreDetails';
import JBImage from 'components/essentials/JBImage';
import { PRODUCT_GRID_FRAGMENT_LITE } from 'mocks/queries';
import { GET_PRODUCT_OPTIONS_LEAD_TIMES } from 'data-hooks/usePDPLeadTime/queries';
import useSuspenseQuery from 'commons/useSuspenseQuery';
import useUserLocation from 'global-state/userLocation/useUserLocation';

const GET_PRODUCT_MINIMAL_LAYOUT_WITH_QSSTOCK = gql`
  query getProduct(
    $slug: String
    $id: Int
    $zipCode: String
    $regionId: String
    $stateCode: String
    $fromCache: Boolean
    $productSku: String
  ) {
    product: getProduct(slug: $slug, id: $id, productSku: $productSku) {
      id
      sku
      slug
      name
      default_option_value
      default_option_image
      price
      efg_flag
      is_external
      upholstery_product {
        attributes_min {
          urlKey
        }
      }
      qs_stock(zipCode: $zipCode, fromCache: $fromCache) {
        stock
        errors {
          message
        }
      }
      attributes {
        code
        label
        name
        value
      }
      hero_images_min {
        sort_order
        url
        img {
          url
          cropData
        }
      }
      images {
        sort_order
        url
      }
      family {
        name
      }
      type {
        name
      }
      meta {
        id
        active
        inventory_count
        manufacturer
        config {
          fabric_count
          has_cushion
          has_mattress
          has_orientation
          stain_count
        }
        customer {
          favorited
          favOptions {
            optionName
            optionId
            optionType
          }
          is_designer
        }
        price(stateCode: $stateCode, regionId: $regionId) {
          discount_percent
          final_price
          financing_month
          original_price
          max_discount_percent
        }
        promotion {
          active
          template
          type
          endDate
          discount
        }
        shipping {
          lead_time
          quick_ship
          variants {
            id
            lead_time
            bucket_lead_time
            qty
            quick_ship
            variant
            title
            description
            icon
            image
            sku
          }
        }
      }
      ...ProductGridFragmentLite
    }
  }

  ${PRODUCT_GRID_FRAGMENT_LITE}
`;

const ProductCatalogCard = props => {
  const { product } = props;
  const [zipCode, setZipCode] = useState(null);
  const storeData = useStoreDetails();
  const [productImgUrl, setProductImgUrl] = useState(storeData?.zipCode);
  const [optionsParams, setOptionsParams] = useState(null);
  const userLocation = useUserLocation();

  const { data: productData } = useSuspenseQuery(
    GET_PRODUCT_MINIMAL_LAYOUT_WITH_QSSTOCK,
    {
      variables: {
        id: parseInt(product?.product_id, 10),
        zipCode,
        ...getVisitorLocationQueryVariables(userLocation?.stateShortName),
      },
      context: { noBatch: true },
    }
  );

  const [
    getProductLeadTimes,
    { loading: leadTimeLoading, data: leadTimeData },
  ] = useLazyQuery(GET_PRODUCT_OPTIONS_LEAD_TIMES);

  const isDiscontinuedProduct =
    product.visibility === 0 && product.item_availability === 2;

  const setDefaultOptionValues = productInfo => {
    if (isEmpty(productInfo.product.options)) {
      setOptionsParams({});
      return;
    }

    const { options } = productInfo.product;
    const optionsObj = options.reduce((finalObj, curr) => {
      if (curr.default_value) {
        finalObj[curr.id] = [parseInt(curr.default_value, 10)];
      } else {
        finalObj[curr.id] = [parseInt(curr.values[0].option_values[0].id, 10)];
      }
      return finalObj;
    }, {});

    setOptionsParams(optionsObj);
  };

  useEffect(() => {
    if (userLocation?.zip) {
      setZipCode(userLocation?.zip);
    }
  }, [userLocation?.zip]);

  useEffect(() => {
    if (!product?.product_id || isDiscontinuedProduct || !optionsParams) {
      return;
    }
    getProductLeadTimes({
      variables: {
        id: parseInt(product.product_id, 10),
        zipCode,
        options: JSON.stringify(optionsParams),
        noCache: true,
      },
    });
  }, [
    optionsParams,
    userLocation?.zip,
    getProductLeadTimes,
    isDiscontinuedProduct,
    product?.product_id,
    zipCode,
  ]);

  useEffect(() => {
    if (!productData?.product?.name) {
      return;
    }

    setDefaultOptionValues(productData);
    setProductImgUrl(getProductImageUrl(productData.product));
  }, [productData]);

  const qsStock =
    productData?.product?.qs_stock?.stock?.[`${product?.product_id}`];

  let stockQuantity = 0;

  if (!isDiscontinuedProduct && qsStock) {
    Object.entries(qsStock).forEach(([, stockObject]) => {
      if (stockObject.stock > 0) {
        stockQuantity += stockObject.stock;
      }
    });
  }

  const imgSrc = product.small_img ? publicImgBaseURL(product.small_img) : '';

  const constructedImgSrc =
    parseInt(productData?.product?.id, 10) === product.product_id
      ? product.option_name
        ? productImgUrl?.transparent_config_image
        : productImgUrl?.transparent_hero_image
      : '';

  const fallbackImgSrc =
    parseInt(productData?.product?.id, 10) === product.product_id
      ? product.option_name
        ? productImgUrl?.transparent_config_image_ts
        : productImgUrl?.transparent_hero_image_ts
      : '';

  const showPrice = () => {
    if (!productData?.product) {
      return (
        <span className="text-base font-bold text-gray before:[content:'$']">
          {product.price}
        </span>
      );
    }
    const originalPrice = productData?.product?.price || product.price;
    let maxDiscountedAmount = 0;
    let discountedAmount = 0;

    if (productData.product.meta?.price?.max_discount_percent) {
      maxDiscountedAmount = Math.round(
        (originalPrice *
          (100 -
            parseInt(
              productData.product.meta.price.max_discount_percent,
              10
            ))) /
          100
      );
    }
    if (productData.product.meta?.price?.discount_percent) {
      discountedAmount = Math.round(
        (originalPrice *
          (100 -
            parseInt(productData.product.meta.price.discount_percent, 10))) /
          100
      );
    }
    if (discountedAmount && maxDiscountedAmount) {
      return (
        <>
          <span className="text-base font-bold text-gray before:[content:'$'] line-through">
            {originalPrice}
          </span>{' '}
          <span className="text-base font-bold before:[content:'$'] text-red">
            {maxDiscountedAmount}
          </span>
          {' - '}
          <span className="text-base font-bold before:[content:'$'] text-red">
            {discountedAmount}
          </span>
        </>
      );
    }
    if (discountedAmount) {
      return (
        <>
          <span className="text-base font-bold text-gray before:[content:'$'] line-through">
            {originalPrice}
          </span>{' '}
          <span className="text-base font-bold before:[content:'$'] text-red">
            {discountedAmount}
          </span>
        </>
      );
    }
    return (
      <span className="text-base font-bold text-gray before:[content:'$']">
        {originalPrice}
      </span>
    );
  };

  const content = (
    <div
      key={product.product_id}
      className="flex flex-row items-center w-full mx-8 my-4 p-5 h-[150px] [border-bottom:1px_solid_#e5e5e5] justify-start max-[1023px]:px-4 max-[1023px]:py-12 max-[1023px]:m-0 max-[1023px]:h-[250px] max-[1023px]:justify-between"
    >
      <div className="flex items-center w-[26rem] min-w-[26rem] mr-[1.2rem] text-gray max-[1023px]:min-w-[unset] max-[1023px]:w-[unset] max-[1023px]:flex-col max-[1023px]:items-center">
        <JBImage
          src={constructedImgSrc || fallbackImgSrc || imgSrc}
          fallbackSrc={fallbackImgSrc || imgSrc}
          fallbackSrc2={imgSrc}
          className="opacity-100 transition-[opacity_1s] object-contain max-w-[12.5rem] h-28 min-h-[7rem] mr-[1.2rem] min-w-[13rem]"
          loader
          width={368}
          trim="color"
          trimPad={20}
        />
        <div className="w-full max-[1023px]:w-[unset]">
          <div className="text-base font-bold text-gray">
            {product.name || ''}
          </div>

          {showPrice()}
        </div>
      </div>

      <div className="max-[1023px]:flex max-[1023px]:flex-col max-[1023px]:min-w-[11rem]">
        <span className="lg:hidden"> </span>

        <span className="text-base w-full ml-[1.2rem] text-gray max-[1023px]:ml-0 before:max-[1023px]:[content:'SKU:_']">
          {' '}
          {product.serial_number}
        </span>
        <span className="text-base w-full text-gray before:max-[1023px]:[content:'LeadTime:_']">
          {isDiscontinuedProduct
            ? ' - '
            : leadTimeData?.getProductOptionsLeadTime
            ? `${Math.ceil(
                (leadTimeData?.getProductOptionsLeadTime?.lead_time || 0) / 7
              )} weeks`
            : leadTimeLoading
            ? 'Loading...'
            : ''}{' '}
        </span>

        <span className="text-base w-full font-bold text-gray before:max-[1023px]:[content:'Stock:_']">
          {isDiscontinuedProduct ? (
            <span className="text-red"> Discontinued product </span>
          ) : stockQuantity ? (
            <span className="text-brand">
              {' '}
              {`Ready to ship (${stockQuantity})`}
            </span>
          ) : (
            <span className="text-red">Out of stock</span>
          )}
        </span>

        <span className="lg:hidden"> </span>
      </div>
    </div>
  );

  return (
    <div className="product-catalog-card">
      {product ? (
        isDiscontinuedProduct || !product.url_key ? (
          content
        ) : (
          <Link
            to={`${product.url_key}`}
            className="no-underline [&_a]:no-underline [&_a:active]:no-underline [&_a:hover]:no-underline [&_a:link]:[&:link]:no-underline [&_a:visited]:no-underline"
          >
            {content}
          </Link>
        )
      ) : null}
    </div>
  );
};

ProductCatalogCard.propTypes = {
  product: PropTypes.objectOf(PropTypes.object),
};

export default ProductCatalogCard;

import React from 'react';
import PropTypes from 'prop-types'

const IconBirdLogo = props => {
  const color = props.color || '#585858';
  return (
    <svg width={16} height={11} {...props} viewBox="0 0 16 11">
      <g fill={color} fillRule="nonzero">
        <path d="M10.45 4.4c-1.46 2.334-4.36 2.057-8.283-.248-.981-.575-1.796.113-2.04.945-.552-2.282.797-2.939 2.225-2.136 3.267 1.836 5.747 3.77 8.097 1.44z" />
        <path d="M8.56 8.304c3.846-.486 5.04-3.667 6.035-7.286l1.12-.22-.997-.229C14.143 0 13.556-.1 13.048.085c-.542.199-.926.685-1.184 1.169-.97 1.813-.845 4.7-3.762 5.09-2.016.27-4.031-.641-6.138-1.89-.81-.478-2.209.656-.956 1.64 1.634 1.285 3.99 2.393 6.824 2.27h.003c.067.21.266.941.034 1.676-.992.456-3.526.45-3.908.908h8.453c-.383-.458-2.915-.452-3.907-.908-.275-.869.054-1.736.054-1.736zm5.212-7.96c.115 0 .21.092.21.204a.206.206 0 01-.21.204.207.207 0 01-.21-.204c0-.112.094-.203.21-.203z" />
      </g>
    </svg>
  );
};

IconBirdLogo.propTypes = {
  color: PropTypes.string
}

export default IconBirdLogo;

import React from 'react';
import PropTypes from 'prop-types';

import { colorGray } from 'styles/layout/colors';

const IconFilter2 = ({ color = colorGray, ...props }) => (
  <svg width="12px" height="17px" viewBox="0 0 12 17" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-26.000000, -170.000000)" fill={color}>
        <g transform="translate(25.000000, 170.000000)">
          <path d="M10.3442703,2.67275676 L10.3442703,0.658410811 C10.3442703,0.308748649 10.0611108,0.0255891892 9.71144865,0.0255891892 C9.36083784,0.0255891892 9.07767838,0.308748649 9.07767838,0.658410811 L9.07767838,2.72977297 M9.07767838,7.03396216 L9.07767838,16.3684216 C9.07767838,16.7180838 9.36083784,17.0012432 9.71144865,17.0012432 C10.0611108,17.0012432 10.3442703,16.7180838 10.3442703,16.3684216 L10.3442703,7.01693514" />
          <path d="M12.6132432,4.83154054 C12.6132432,3.26186757 11.3400108,1.98851351 9.76851351,1.98851351 C8.19789189,1.98851351 6.92378378,3.26174595 6.92378378,4.83154054 C6.92378378,6.40133514 8.19796486,7.67456757 9.76851351,7.67456757 C11.3400838,7.67456757 12.6132432,6.40133514 12.6132432,4.83154054 Z M8.19108108,4.83154054 C8.19108108,3.96119189 8.89704595,3.25615135 9.76836757,3.25615135 C10.6406135,3.25615135 11.3466027,3.96116757 11.3466027,4.83154054 C11.3466027,5.70191351 10.6406378,6.40692973 9.76836757,6.40692973 C8.89707027,6.40692973 8.19108108,5.70191351 8.19108108,4.83154054 Z" />
          <path d="M3.25032432,14.3542703 L3.25032432,16.3686162 C3.25032432,16.7182784 3.53348378,17.0014378 3.88314595,17.0014378 C4.23375676,17.0014378 4.51691622,16.7182784 4.51691622,16.3686162 L4.51691622,14.2972541 M4.51691622,9.99306486 L4.51691622,0.658605405 C4.51691622,0.308943243 4.23375676,0.0257837838 3.88314595,0.0257837838 C3.53348378,0.0257837838 3.25032432,0.308943243 3.25032432,0.658605405 L3.25032432,10.0100919" />
          <path d="M0.981351351,12.1954865 C0.981351351,13.7651595 2.25458378,15.0385135 3.82608108,15.0385135 C5.3967027,15.0385135 6.67081081,13.7652811 6.67081081,12.1954865 C6.67081081,10.6256919 5.39662973,9.35245946 3.82608108,9.35245946 C2.25451081,9.35245946 0.981351351,10.6256919 0.981351351,12.1954865 Z M5.40351351,12.1954865 C5.40351351,13.0658351 4.69754865,13.7708757 3.82622703,13.7708757 C2.95398108,13.7708757 2.24799189,13.0658595 2.24799189,12.1954865 C2.24799189,11.3251135 2.95395676,10.6200973 3.82622703,10.6200973 C4.69752432,10.6200973 5.40351351,11.3251135 5.40351351,12.1954865 Z" />
        </g>
      </g>
    </g>
  </svg>
);

IconFilter2.propTypes = {
  color: PropTypes.string,
};

export default IconFilter2;

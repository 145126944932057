import isClient from 'commons/isClient';

const getScrollPositionOfWindowOrBody = () => {
  if (!isClient()) {
    return 0;
  }
  const windowScrollPosition = window.pageYOffset;
  const bodyScrollPosition = document.body.scrollTop;
  return Math.max(windowScrollPosition, bodyScrollPosition);
};

export default getScrollPositionOfWindowOrBody;

import getVisitorRegionId from './getVisitorRegionId';
import getVisitorStateCode from './getVisitorStateCode';
import omitNil from './omitNil';

const getVisitorLocationQueryVariables = (stateShortName, regionId) =>
  omitNil({
    regionId: getVisitorRegionId() || regionId,
    stateCode: stateShortName || getVisitorStateCode(),
  });

export default getVisitorLocationQueryVariables;

import isClient from 'commons/isClient';
import getHttpContextValue from './getHttpContextValue';

const getVisitorRegionId = () => {
  if (isClient()) {
    return new URLSearchParams(window.location.search).get('region_id');
  }
  return getHttpContextValue('regionId', null);
};

export default getVisitorRegionId;

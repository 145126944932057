import { getUserLocationCookie } from './localStorage';

const getZipcode = () => {
  const userLocationFromCookie = getUserLocationCookie();

  return userLocationFromCookie && userLocationFromCookie.zip
    ? userLocationFromCookie.zip.toString()
    : null;
};

export default getZipcode;

import React from 'react';
import PropTypes from 'prop-types';

import { colorGrayLight1 } from 'styles/layout/colors';

const IconMobileGrid2x = ({ color = colorGrayLight1, ...otherProps }) => (
  <svg width="29px" height="29px" viewBox="0 0 29 29" {...otherProps}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-331.000000, -164.000000)"
        stroke={color}
        strokeWidth="1.25"
      >
        <g transform="translate(331.000000, 164.000000)">
          <path d="M0.625,12.375 L12.375,12.375 L12.375,0.625 L0.625,0.625 L0.625,12.375 Z" />
          <path d="M0.625,28.375 L12.375,28.375 L12.375,16.625 L0.625,16.625 L0.625,28.375 Z" />
          <path d="M16.625,12.375 L28.375,12.375 L28.375,0.625 L16.625,0.625 L16.625,12.375 Z" />
          <path d="M16.625,28.375 L28.375,28.375 L28.375,16.625 L16.625,16.625 L16.625,28.375 Z" />
        </g>
      </g>
    </g>
  </svg>
);

IconMobileGrid2x.propTypes = {
  color: PropTypes.string,
};

export default IconMobileGrid2x;

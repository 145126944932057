import React from 'react';
import PropTypes from 'prop-types'

const ArrowDownSimples = ({ color = '#000', ...otherProps }) => (
  <svg width={9} height={5} viewBox="0 0 9 5" {...otherProps}>
    <path
      fill={color}
      fillRule="nonzero"
      d="M4.462 5L0 .672.692 0 4.46 3.732 8.309 0 9 .672z"
    />
  </svg>
);

ArrowDownSimples.propTypes = {
  color: PropTypes.string
}

export default ArrowDownSimples;

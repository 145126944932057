import { FABRIC_IMG_URL, WOOD_PRODUCT_OPTION_ID } from './constants';
import addDomainToPath from './addDomainToPath';

const constructFabricUrl = (
  fabricName,
  type = '',
  forPage = null,
  optionId = null
) => {
  if (!fabricName) return null;
  let finalFabricName = fabricName;
  if (forPage && forPage === 'shop-color' && optionId) {
    finalFabricName = `${optionId}-${fabricName
      .trim()
      .toLowerCase()
      .split(' ')
      .join('-')}`;
  } else {
    finalFabricName = fabricName.trim().toLowerCase();
  }
  if (type === 'Wood' || type === WOOD_PRODUCT_OPTION_ID) {
    finalFabricName = fabricName;
  }
  return {
    transparent_config_image: addDomainToPath(
      `${FABRIC_IMG_URL}${finalFabricName}.jpg`
    ),
  };
};

export default constructFabricUrl;

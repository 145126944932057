import React from 'react';
import PropTypes from 'prop-types';

const IconMail = props => {
  const color = props.color || '#585858';
  return (
    <svg width={24} height={19} {...props} viewBox="0 0 24 19">
      <g stroke={color} strokeWidth={1.5} fill="none" fillRule="evenodd">
        <path d="M21 18H3a2 2 0 01-2-2V3a2 2 0 012-2h18a2 2 0 012 2v13a2 2 0 01-2 2z" />
        <path d="M1 2l9.861 8.212a2.002 2.002 0 002.278 0L23 2" />
      </g>
    </svg>
  );
};

IconMail.propTypes = {
  color: PropTypes.string,
};

export default IconMail;

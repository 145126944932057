import Cookies from 'universal-cookie';

import isClient from './isClient';
import getHttpContextValue from './getHttpContextValue';
import isNewConsumerApp from './isNewConsumerApp';

const cookies = new Cookies();

const getCookies = () => {
  // If running in Next.js app, temporarily return the mock cookies object on
  // both client and server. This will be replaced with Next.js-based cookie
  // handling in CON-5668.
  if (isClient() || isNewConsumerApp) {
    return cookies;
  }
  return getHttpContextValue('universalCookies');
};

export default getCookies;

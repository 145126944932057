import React from 'react';
import PropTypes from 'prop-types';

const IconArrowRightSimple = ({
  color = '#585858',
  width = 9,
  height = 16,
  ...props
}) => (
  <svg width={width} height={height} viewBox="0 0 9 16" {...props}>
    <path
      stroke={color}
      strokeWidth={2}
      d="M1 1l7 7.081L1.161 15"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconArrowRightSimple.propTypes = {
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default IconArrowRightSimple;
